<template>
  <v-container
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <v-btn
          color="primary"
          fab
          dark
          to="/pages/perfil/create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            Perfis
            <v-spacer />
            <v-text-field
              v-model="filter.Term"
              label="Buscar"
              single-line
              hide-details
            />
            <v-btn
              color="primary"
              @click="load"
            >
              Buscar
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="perfis"
            :options.sync="options"
            :server-items-length="total"
            class="elevation-1"
            :footer-props="footer_props"
          >
            <template v-slot:item.action="{ item }">
              <router-link :to="`/pages/perfil/${item.perfilId}`">
                <v-icon
                  class="mr-2"
                  color="info"
                >
                  mdi-pencil
                </v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    data: () => ({
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      itemselected: {},
      perfis: [],
      headers: [
        { text: 'Nome', value: 'nome', sortable: false },
        { text: 'Ações', value: 'action', sortable: false },
      ],
      dialog: false,
      total: 0,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
        Term: '',
      },
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      load: function () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        const query = JSON.stringify(this.filter)
        this.$http.get('/perfis', { params: { query } })
          .then(resp => {
            this.perfis = resp.data.rows
            this.total = resp.data.total
          })
      },
    },
  }
</script>

<style>
a {
  text-decoration: none;
}
</style>
